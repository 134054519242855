import React from "react"
import { FelaComponent } from "react-fela"
import Text from "../images/anet_and_luky.svg"

import style from "./MainTitle.style"

const MainTitle = props => (
  <FelaComponent as="span" style={style}>
    {({ className }) => (
      <span className={className}>
        <Text />
      </span>
    )}
  </FelaComponent>
)

export default MainTitle

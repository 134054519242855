import tinycolor from "tinycolor2"

export default ({ theme: { font, navbar, breakpoints }, isSticky }) => ({
  height: `${navbar.height}px`,
  background: isSticky ? "rgb(0, 0, 0)" : navbar.bgColor,
  fontFamily: font.family,
  zIndex: "100",

  [breakpoints.lgUp]: {
    justifyContent: "center",
  },

  "& .nav": {
    marginTop: "0.5rem",

    [breakpoints.lgUp]: {
      margin: "auto",
    },
  },

  "& .navbar-toggler": {
    border: "0",
    outlineColor: "white",
    outlineWidth: "1px",
  },

  "& .nav-item": {
    display: "flex",
    alignItems: "center",
    width: "100%",
    background: tinycolor(navbar.bgColor)
      .setAlpha(0.9)
      .toRgbString(),
    textTransform: "uppercase",

    [breakpoints.lgUp]: {
      width: "auto",
      background: "transparent",

      "&:after": {
        content: '"|"',
        fontSize: "1.3rem",
        color: "white",
      },

      "&:last-child:after": {
        content: '""',
      },
    },
  },

  "& .nav-link": {
    fontSize: "1.3rem",
    padding: "0.35rem 0.7rem",
    color: "white",

    "&:hover, &:active": {
      color: "white",
    },
  },

  "& .navbar-brand": {
    padding: "0",
  },
})

/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { StickyContainer, Sticky } from "react-sticky"
import { ThemeProvider, FelaRenderer } from "react-fela"

import Navbar from "./Navbar"
import Header from "./Header"
import Footer from "./Footer"
import theme from "../styles/theme"

import fonts from "../fonts"
import styles from "../styles"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            subTitle
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <FelaRenderer>
          {renderer => {
            fonts.forEach(font => {
              renderer.renderFont(font.name, font.files, font.options)
            })

            styles.forEach(style => {
              renderer.renderStatic(style.toString())
            })

            return (
              <>
                <Header
                  siteTitle={data.site.siteMetadata.title}
                  siteSubTitle={data.site.siteMetadata.subTitle}
                />
                <StickyContainer className="sticky-container">
                  <Sticky>
                    {({ style, isSticky }) => (
                      <Navbar style={style} isSticky={isSticky} theme={theme} />
                    )}
                  </Sticky>
                  <div>
                    <main>{children}</main>
                    <Footer />
                  </div>
                </StickyContainer>
              </>
            )
          }}
        </FelaRenderer>
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React from "react"
import PropTypes from "prop-types"
import { FelaComponent } from "react-fela";
import InstagramLogo from "../images/logo-instagram.svg"

import barStyle from "./SocialBar.style"

const SocialBar = ({ style, isSticky }) => (
  <FelaComponent
    isSticky={isSticky}
    style={barStyle}
    additionalCss={style}
  >
    <a
      className="item"
      href="https://www.instagram.com/explore/tags/bacisoviwedding"
      target="_blank"
      rel="noopener noreferrer"
    >
      #bacisoviwedding
      <InstagramLogo />
    </a>
  </FelaComponent>
)

SocialBar.propTypes = {
  style: PropTypes.object,
  isSticky: PropTypes.bool,
}

SocialBar.defaultProps = {
  isSticky: false,
}

export default SocialBar

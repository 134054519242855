import tinycolor from "tinycolor2"

export default ({
  theme: { breakpoints, navbar, colors },
  isSticky,
  additionalCss,
}) => ({
  position: "fixed",
  top: "0",
  height: `${navbar.height}px`,
  display: "inline-flex",
  alignItems: "center",
  zIndex: "200",

  ...additionalCss,
  // @override = need to override inline styles from Sticky component
  ...(isSticky
    ? {
        [breakpoints.smUp]: {
          left: "100px !important", // @override,
        },

        [breakpoints.lgDown]: {
          width: "initial !important", // @override,
        },

        [breakpoints.lgUp]: {
          top: `${navbar.height}px !important`, // @override,
          left: "0 !important", // @override,
          alignItems: "flex-start",
        },
      }
    : {}),

  "& .item": {
    display: "inline-flex",
    alignItems: "center",
    padding: "1.5rem 0.5rem",
    fontFamily: "$font-navbar",
    color: "white",
    fontSize: "1rem",
    height: "1.5rem",
    textTransform: "uppercase",
    background: isSticky ? colors.black : undefined,
    borderBottomRightRadius: isSticky ? "3px" : undefined,

    [breakpoints.smUp]: {
      fontSize: "1.1rem",
    },

    [breakpoints.mdUp]: {
      fontSize: "1.3rem",
    },

    "& svg": {
      marginLeft: "0.5rem",
      height: "1em",
    },

    "&:hover": {
      textDecoration: "none",
      color: tinycolor(colors.white)
        .darken(20)
        .toRgbString(),
    },
  },
})

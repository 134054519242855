import PropTypes from "prop-types"
import React from "react"
import { FelaComponent } from "react-fela";
import MainImage from "./MainImage"
import MainTitle from "./MainTitle"

import style from "./Header.style"

const Header = ({ siteTitle, siteSubTitle }) => (
  <FelaComponent style={style}>
    {({className}) => (
      <header className={`text-light ${className}`}>
        <MainImage>
          <MainTitle />
          <h2 className="subtitle">{siteSubTitle}</h2>
        </MainImage>
      </header>
    )}
  </FelaComponent>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteSubTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  siteSubTitle: ``,
}

export default Header

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

const timeline = {
  leadingLineWidth: 2,
  itemSize: 5,
  itemLabelMargin: 1,
  itemTimeFontSize: 1.2,
  itemTitleFontSize: 1.1,
}

export default {
  font: {
    family: "Verdana, sans-serif",
    size: "1rem",
  },
  navbar: {
    height: 56,
    bgColor: "rgba(0, 0, 0, 0.6)",
  },
  photo: {
    contentPadding: 10,
  },
  colors: {
    black: "#000",
    white: "#FFF",
  },
  timeline,
  sections: {
    maxWidth: 960,
  },
  breakpoints: {
    xs: breakpoints.xs,
    sm: breakpoints.sm,
    md: breakpoints.md,
    lg: breakpoints.lg,
    xl: breakpoints.xl,
    xsUp: `@media (min-width: ${breakpoints.xs}px)`,
    smUp: `@media (min-width: ${breakpoints.sm}px)`,
    mdUp: `@media (min-width: ${breakpoints.md}px)`,
    lgUp: `@media (min-width: ${breakpoints.lg}px)`,
    xsDown: `@media (max-width: ${breakpoints.xs}px)`,
    smDown: `@media (max-width: ${breakpoints.sm}px)`,
    mdDown: `@media (max-width: ${breakpoints.md}px)`,
    lgDown: `@media (max-width: ${breakpoints.lg}px)`,
  },
}

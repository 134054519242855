import React from "react"
import PropTypes from "prop-types"
import LogoImg from "../images/al_logo.svg"

const Logo = ({ height }) => <LogoImg height={height} />

Logo.propTypes = {
    height: PropTypes.number,
}

export default Logo

export default ({ theme: { colors, breakpoints } }) => ({
  marginBottom: "10px",
  width: "90%",

  [breakpoints.smUp]: {
    width: "70%",
  },

  [breakpoints.mdUp]: {
    width: "50%",
  },

  "& path": {
    fill: colors.white,
  },
})

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-background-image"
import style from "./MainImage.style"
import { FelaComponent } from "react-fela"

const Image = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "backgrounds/header-married.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <FelaComponent style={style}>
        {({ className }) => (
          <Img
            className={`${className} d-flex flex-column justify-content-center align-items-center`}
            fluid={data.placeholderImage.childImageSharp.fluid}
          >
            {children}
          </Img>
        )}
      </FelaComponent>
    )}
  />
)
export default Image

import LatoRegular from "./Lato-Regular.ttf"
import LatoBlack from "./Lato-Black.ttf"

export default [
  {
    name: "Lato",
    files: [LatoRegular],
    options: {
      fontStyle: "normal",
      fontWeight: "400",
      fontDisplay: "swap",
    },
  },
  {
    name: "Lato",
    files: [LatoBlack],
    options: {
      fontStyle: "normal",
      fontWeight: "700",
      fontDisplay: "swap",
    },
  },
]

import PropTypes from "prop-types"
import React from "react"
import {
  Navbar as BsNavbar,
  NavbarBrand,
  Nav,
  NavbarToggler,
  Collapse,
  NavItem,
} from "reactstrap"
import { FelaComponent } from "react-fela"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Logo from "./Logo"
import SocialBar from "./SocialBar"

import navbarStyle from "./Navbar.style"

class Navbar extends React.Component {
  state = { navbarOpen: false }

  render() {
    const { navbarOpen } = this.state
    const { style, isSticky, theme } = this.props
    return (
      <React.Fragment>
        <SocialBar style={style} isSticky={isSticky} />
        <FelaComponent style={navbarStyle} isSticky={isSticky}>
          {({ className }) => (
            <BsNavbar className={className} style={style} dark expand="lg">
              <NavbarBrand>
                <Logo height={40} />
              </NavbarBrand>
              <NavbarToggler
                onClick={() => this.setState({ navbarOpen: !navbarOpen })}
              />
              <Collapse isOpen={navbarOpen} navbar>
                <Nav>
                  {[
                    { id: "playlists", title: "Hudba" },
                    { id: "instagram", title: "Instantní příběh" },
                    { id: "photostory", title: "Fotopříběh" },
                    /* { id: "lineup", title: "Line up" },
                    { id: "svedci", title: "Svědci" },
                    { id: "info", title: "Důležité info" },
                    { id: "kontakt", title: "Kontakt" }, */
                  ].map(({ id, title }) => (
                    <NavItem key={id}>
                      <AnchorLink
                        href={`#${id}`}
                        className="nav-link"
                        offset={theme.navbar.height}
                        onClick={() => this.setState({ navbarOpen: false })}
                      >
                        {title}
                      </AnchorLink>
                    </NavItem>
                  ))}
                </Nav>
              </Collapse>
            </BsNavbar>
          )}
        </FelaComponent>
      </React.Fragment>
    )
  }
}

Navbar.propTypes = {
  style: PropTypes.object,
}

Navbar.defaultProps = {
  style: null,
}

export default Navbar

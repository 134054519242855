import React from 'react';

const Footer = () => (
    <footer className="text-center bg-dark text-light">
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.org" className="text-light">Gatsby</a>
    </footer>      
);

export default Footer;
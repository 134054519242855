export default ({ theme: { breakpoints } }) => ({
  minHeight: "384px",

  "&:before": {
    backgroundPositionX: "center",
  },

  [breakpoints.smUp]: {
    minHeight: "512px",
  },

  [breakpoints.mdUp]: {
    minHeight: "768px",
  },
})

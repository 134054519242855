export default ({ theme }) => ({
  fontFamily: theme.font.family,
  marginBottom: `${-1 * theme.navbar.height}px`, // this move navbar over main image

  "& .subtitle": {
    fontSize: "1.5rem",
    marginTop: "0.5rem",
    textTransform: "uppercase",
  },
})
